$(document).ready(function () {
  // Disable MouseScrollEvent for all number fields
  $(document).on("wheel", "input[type=number]", function (e) {
    $(this).blur();
  });


  $(document).on('change', '.checkAll', function() {
    var targetId = $(this).data('target-class')
    $('.childCheckbox'+targetId).prop('checked', this.checked);
  });

  $(document).on("change", ".shift-assignment-until-end", function() {
    if (this.checked) {
      $(".shift-end-date-field").addClass("hidden")
      $(".shift-assignment-end-date").prop('required', false);
    } else {
      $(".shift-end-date-field").removeClass("hidden")
      $(".shift-assignment-end-date").prop('required', true);
    }
  })

  $(document).on('change', 'input[name="attendance[ot_status]"]', function (event) {
    var newValue = $(this).val();
    if (newValue === 'ot_rejected') {
      $("#attendance_approved_ot").val(0)
    } else {
      $("#attendance_approved_ot").val($(this).data('calculated-ot'))
    }
  });

  $(document).on('click', '.remove_fields', function (event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('section').hide();
    return event.preventDefault();
  });

  $(document).on('click', '.add_form_fields', function (event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  $(document).on('change', '#leave_type_carry_forwardable', function () {
    if($(this).is(":checked")){
      $('#max_cf_days').removeClass('hidden');
    }else{
      $('#max_cf_days').addClass('hidden')
    }
  });
  $(document).on('change', '#leave_type_avail_all', function () {
    if($(this).is(":checked")){
      $('#available_in_days').addClass('hidden')
    }else{
      $('#available_in_days').removeClass('hidden');
    }
  });

  // Horizontal scrolling
  $('#scrollLeft').click(function() {
    $('.overflow-x-content').animate({
      scrollLeft: '-=400' // Scrolls 100 pixels to the left
    }, 'smooth');
  });

  $('#scrollRight').click(function() {
    $('.overflow-x-content').animate({
      scrollLeft: '+=400' // Scrolls 100 pixels to the right
    }, 'smooth');
  });

  function toggleScrollButtons() {
    var scrollContainer = $('.overflow-x-content');
    if (scrollContainer.length > 0) {
      if (scrollContainer.outerWidth() < scrollContainer.get(0).scrollWidth) {
        $('.scroll-buttons').show();
      } else {
        $('.scroll-buttons').hide();
      }
    }
  }

  // Call on document ready
  toggleScrollButtons();

  // Optional: Call on window resize
  $(window).resize(toggleScrollButtons);

  // Disable future month selection
  if ($('#monthInput').length) {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0 indexed, pad with a 0 if necessary
  
    var maxMonth = currentYear + '-' + currentMonth;
  
    $('#monthInput').attr('max', maxMonth);
  }

  // Disable past month selection
  if ($('#monthInputForFuture6m').length) {
    var today = new Date();
    var currentYear = today.getFullYear();
    var currentMonth = today.getMonth() + 1; // getMonth() is zero-based, add 1 for the correct month
    currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth; // add leading zero if needed
    var minMonth = currentYear + '-' + currentMonth;

    var sixMonthsLater = new Date(today.setMonth(today.getMonth() + 6));
    var sixMonthsYear = sixMonthsLater.getFullYear();
    var sixMonthsMonth = sixMonthsLater.getMonth() + 1; // getMonth() is zero-based, add 1 for the correct month
    sixMonthsMonth = sixMonthsMonth < 10 ? '0' + sixMonthsMonth : sixMonthsMonth; // add leading zero if needed
    var maxMonth = sixMonthsYear + '-' + sixMonthsMonth;

    var monthInput = document.getElementById('monthInputForFuture6m');
    monthInput.setAttribute('min', minMonth);
    monthInput.setAttribute('max', maxMonth);
  }




});

// Submit form on change of checkbox by sending the form ID in the attribute and adding the class name as submit-on-change-checkbox.
$(document).ready(function() {
  $('.submit-on-change-checkbox').on('change', function() {
    var formId = $(this).data('attribute');
    submitForm(formId);
  });

  function submitForm(formId) {
    $("#" + formId).submit();
  }
});

$(document).ready(function() {
  function toggleBackdateLimit() {
    if ($('#app_configuration_allow_manual_attendance').is(':checked')) {
      $('#backdate-limit-row').show();
    } else {
      $('#backdate-limit-row').hide();
    }
  }
  toggleBackdateLimit();
  $('#app_configuration_allow_manual_attendance').on('change', function() {
    toggleBackdateLimit();
  });

  $('#app_configuration_comp_off_allowed_comp_off').on('change', function() {
    if ($(this).is(':checked')) {
      $('.comp_off_row').show();
      $('.overtime_row').hide();
    } else {
      $('.comp_off_row').hide();
      $('.overtime_row').show();
    }
  });

  $('#app_configuration_comp_off_allowed_overtime').on('change', function() {
    if ($(this).is(':checked')) {
      $('.overtime_row').show();
      $('.comp_off_row').hide();
    } else {
      $('.overtime_row').hide();
      $('.comp_off_row').show();
    }
  });

  $('#app_configuration_comp_off_allowed_overtime').on('change', function() {
    if ($(this).is(':checked')) {
      $('.overtime_row').show();
      $('.comp_off_row').hide();
    } else {
      $('.overtime_row').hide();
      $('.comp_off_row').show();
    }
  });
});
